<!-- 权限申请与使用情况说明 -->

<template>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>权限申请与使用情况说明</title>
  </head>

  
    <div id="write" class="first-line-indent">
      <h3 id="权限申请与使用情况说明">权限申请与使用情况说明</h3>
      <p>
        1.为保障APP能实现与安全稳定运行之目的，我们可能会申请或使用操作系统的相关权限；
      </p>
      <p>
        2.为保障您的知情权，我们通过下列列表将产品可能申请、使用的相关操作系统权限进行展示，您可以根据实际需要对相关权限进行管理；
      </p>
      <p>
        3.根据产品的升级，申请、使用权限的类型与目的可能会有变动，我们将及时根据这些变动对列表进行调整，以确保您及时获悉权限的申请与使用情况；
      </p>
      <p>
        4.请您知悉，我们为业务与产品的功能与安全需要，我们可能也会使用第三方SDK，这些第三方也可能会申请或使用相关操作系统权限；
      </p>
      <p>
        5.在使用产品的过程中，您可能会使用第三方开发的H5页面或小程序，这些第三方开发开发的插件或小程序也可能因业务功能所必需而申请或使用相关操作系统权限;
      </p>
      <p>
        6.本说明适用于24小时买卖网安卓版、iOS版、具体的适用范围将在以下列表中说明。
      </p>
      <p>Android操作系统应用使用权限：</p>
      <p>
        1.读取SD卡中的内容（读取存储空间/照片权限）android.permission.READ_EXTERNAL_STORAGE
      </p>
      <p>权限功能说明：提供读取手机储存空间内相册图片数据的功能</p>
      <p>
        使用场景与目的：允许App读取存储中的图片、文件等内容，主要用于帮助您上传用户头像等图片、文件、信息等功能
      </p>
      <p>2.访问数据网络状态 ACCESS_NETWORK_STATE</p>
      <p>权限功能说明：获取手机数据流量的网络状态功能</p>
      <p>使用场景与目的：在APP中使用4G/wifi开关状态等信息</p>
      <p>3.访问数据网络状态 ACCESS_WIFI_STATE</p>
      <p>权限功能说明：获取手机WIFI的网络状态功能</p>
      <p>使用场景与目的：在APP中使用wifi开关状态等信息</p>
      <p>4.访问互联网网络权限 android.permission.internet</p>
      <p>权限功能说明：网络访问权限，当APP需要访问网络时使用此权限</p>
      <p>使用场景与目的：在APP启动时加载数据等操作时使用</p>
      <p>
        5.获取手机状态（设备信息（IMEI/MAC/Android
        ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置等）
      </p>
      <p>
        权限功能说明：提供读取手机设备标识等信息，请您放心该权限无法监听、获取您的任何通话内容与信息
      </p>
      <p>
        使用场景与目的：我们可能使用你的设备信息，在退出APP后，保证app正常运行等功能，以及我们的关联方、合作方获得你授权或依法可以提供给我们的信息，用于判断帐号安全、交易安全、进行身份验证、服务日志信息、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施/安全保障等主要功能。
      </p>
      <p>6、相机权限 CAMERA</p>
      <p>权限功能说明：获取用户手机相册内容</p>
      <p>
        使用场景与目的：在应用中使用头像更换功能时，需要获取用户相册照片用于更换用户头像
      </p>
      <p>7、获取网络设备硬件MAC地址 getMacAddress</p>
      <p>权限功能说明：获取设备序列号，网络设备硬件地址MAC等</p>
      <p>
        使用场景与目的：我们获取MAC地址是为了更真实地为还原Crash场景服务，提供分享回流等数据分析服务，以便向目标设备以及指定社交账号分享消息，提供反作弊服务，收集网关取号报错日志等数据以提供统计分析服务能力。
      </p>
     
      <p>8、获取GPS 网络位置信息</p>
      <p>权限功能说明：获取精准地理位置信息，获取大致地理位置信息</p>
      <p>
        使用场景与目的：地理位置权限，包含通过全球定位系统（GPS）或网络位置信息（例如基站和WLAN）等传感器信息获取精准地理位置信息，及通过网络位置信息（例如基站和WLAN）获取大致地理位置信息。
      </p>
      
    </div>
  
</template>

<script>
export default {
  name: "permissionsAndroid",
  data() {
    return {};
  },
};
</script>

<style scoped>
html {
  overflow-x: initial !important;
}

:root {
  --bg-color: #ffffff;
  --text-color: #333333;
  --select-text-bg-color: #b5d6fc;
  --select-text-font-color: auto;
  --monospace: "Lucida Console", Consolas, "Courier", monospace;
  --title-bar-height: 20px;
}

.mac-os-11 {
  --title-bar-height: 28px;
}

html {
  font-size: 14px;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  color: #34495e !important;
}

body {
  margin: 0;
  padding: 0;
  height: auto;
  inset: 0;
  font-size: 1rem;
  overflow-x: hidden;
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: inherit;
  tab-size: 4;
  background-position: inherit;
  background-repeat: inherit;
}

iframe {
  margin: auto;
}

a.url {
  word-break: break-all;
}

a:active,
a:hover {
  outline: 0;
}

.in-text-selection,
::selection {
  text-shadow: none;
  background: var(--select-text-bg-color);
  color: var(--select-text-font-color);
}

#write {
  margin: 0 auto;
  height: auto;
  width: inherit;
  word-break: normal;
  word-wrap: break-word;
  position: relative;
  white-space: normal;
  overflow-x: visible;
  padding-top: 36px;
}

#write.first-line-indent li p,
#write.first-line-indent p * {
  text-indent: 0;
}

#write.first-line-indent li {
  margin-left: 2em;
}

.for-image #write {
  padding-left: 8px;
  padding-right: 8px;
}

body.typora-export {
  padding-left: 30px;
  padding-right: 30px;
}

.typora-export .footnote-line,
.typora-export li,
.typora-export p {
  white-space: pre-wrap;
}

.typora-export .task-list-item input {
  pointer-events: none;
}

@media screen and (max-width: 500px) {
  body.typora-export {
    padding-left: 0;
    padding-right: 0;
  }

  #write {
    padding-left: 20px;
    padding-right: 20px;
  }

  .CodeMirror-sizer {
    margin-left: 0 !important;
  }

  .CodeMirror-gutters {
    display: none !important;
  }
}

#write li > figure:last-child {
  margin-bottom: 0.5rem;
}

#write ol,
#write ul {
  position: relative;
}

img {
  max-width: 100%;
  vertical-align: middle;
  image-orientation: from-image;
}

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-variant-caps: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  line-height: inherit;
}

input[type="checkbox"],
input[type="radio"] {
  line-height: normal;
  padding: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p,
#write pre {
  width: inherit;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p {
  position: relative;
}

p {
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  break-after: avoid-page;
  break-inside: avoid;
  orphans: 4;
}

p {
  orphans: 4;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1rem;
}

.md-math-block,
.md-rawblock,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hidden {
  display: none;
}

.md-blockmeta {
  color: #ccc;
  font-weight: 700;
  font-style: italic;
}

a {
  cursor: pointer;
}

sup.md-footnote {
  padding: 2px 4px;
  background-color: rgba(238, 238, 238, 0.7);
  color: #555;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
}

sup.md-footnote a,
sup.md-footnote a:hover {
  color: inherit;
  text-transform: inherit;
  text-decoration: inherit;
}

#write input[type="checkbox"] {
  cursor: pointer;
  width: inherit;
  height: inherit;
}

figure {
  overflow-x: auto;
  margin: 1.2em 0;
  max-width: calc(100% + 16px);
  padding: 0;
}

figure > table {
  margin: 0;
}

tr {
  break-inside: avoid;
  break-after: auto;
}

thead {
  display: table-header-group;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow: auto;
  break-inside: auto;
  text-align: left;
}

table.md-table td {
  min-width: 32px;
}

.CodeMirror-gutters {
  border-right-width: 0;
  background-color: inherit;
}

.CodeMirror {
  text-align: left;
}

.CodeMirror-placeholder {
  opacity: 0.3;
}

.CodeMirror pre {
  padding: 0 4px;
}

.CodeMirror-lines {
  padding: 0;
}

div.hr:focus {
  cursor: none;
}

#write pre {
  white-space: pre-wrap;
}

#write.fences-no-line-wrapping pre {
  white-space: pre;
}

#write pre.ty-contain-cm {
  white-space: normal;
}

.CodeMirror-gutters {
  margin-right: 4px;
}

.md-fences {
  font-size: 0.9rem;
  display: block;
  break-inside: avoid;
  text-align: left;
  overflow: visible;
  white-space: pre;
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: inherit;
  position: relative !important;
  background-position: inherit;
  background-repeat: inherit;
}

.md-fences-adv-panel {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 8px;
  overflow-x: auto;
}

#write .md-fences.mock-cm {
  white-space: pre-wrap;
}

.md-fences.md-fences-with-lineno {
  padding-left: 0;
}

#write.fences-no-line-wrapping .md-fences.mock-cm {
  white-space: pre;
  overflow-x: auto;
}

.md-fences.mock-cm.md-fences-with-lineno {
  padding-left: 8px;
}

.CodeMirror-line,
twitterwidget {
  break-inside: avoid;
}

.footnotes {
  opacity: 0.8;
  font-size: 0.9rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.footnotes + .footnotes {
  margin-top: 0;
}

.md-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
  text-decoration: none;
  text-shadow: none;
  float: none;
  position: static;
  width: auto;
  height: auto;
  white-space: nowrap;
  cursor: inherit;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  box-sizing: content-box;
  direction: ltr;
  background-position: 0 0;
}

li div {
  padding-top: 0;
}

blockquote {
  margin: 1rem 0;
}

li .mathjax-block,
li p {
  margin: 0.5rem 0;
}

li blockquote {
  margin: 1rem 0;
}

li {
  margin: 0;
  position: relative;
}

blockquote > :last-child {
  margin-bottom: 0;
}

blockquote > :first-child,
li > :first-child {
  margin-top: 0;
}

.footnotes-area {
  color: #888;
  margin-top: 0.714rem;
  padding-bottom: 0.143rem;
  white-space: normal;
}

#write .footnote-line {
  white-space: pre-wrap;
}

@media print {
  body,
  html {
    border: 1px solid transparent;
    height: 99%;
    break-after: avoid;
    break-before: avoid;
    font-variant-ligatures: no-common-ligatures;
  }

  #write {
    margin-top: 0;
    padding-top: 0;
    border-color: transparent !important;
  }

  .typora-export * {
    -webkit-print-color-adjust: exact;
  }

  .typora-export #write {
    break-after: avoid;
  }

  .typora-export #write::after {
    height: 0;
  }

  .is-mac table {
    break-inside: avoid;
  }

  .typora-export-show-outline .typora-export-sidebar {
    display: none;
  }
}

.footnote-line {
  margin-top: 0.714em;
  font-size: 0.7em;
}

a img,
img a {
  cursor: pointer;
}

pre.md-meta-block {
  font-size: 0.8rem;
  min-height: 0.8rem;
  white-space: pre-wrap;
  background-color: #ccc;
  display: block;
  overflow-x: hidden;
}

p > .md-image:only-child:not(.md-img-error) img,
p > img:only-child {
  display: block;
  margin: auto;
}

#write.first-line-indent p > .md-image:only-child:not(.md-img-error) img {
  left: -2em;
  position: relative;
}

p > .md-image:only-child {
  display: inline-block;
  width: 100%;
}

#write .MathJax_Display {
  margin: 0.8em 0 0;
}

.md-math-block {
  width: 100%;
}

.md-math-block:not(:empty)::after {
  display: none;
}

.MathJax_ref {
  fill: currentcolor;
}

[contenteditable="false"]:active,
[contenteditable="false"]:focus,
[contenteditable="true"]:active,
[contenteditable="true"]:focus {
  outline: 0;
  box-shadow: none;
}

.md-task-list-item {
  position: relative;
  list-style-type: none;
}

.task-list-item.md-task-list-item {
  padding-left: 0;
}

.md-task-list-item > input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -1.2em;
  margin-top: calc(1em - 10px);
  border: none;
}

.math {
  font-size: 1rem;
}

.md-toc {
  min-height: 3.58rem;
  position: relative;
  font-size: 0.9rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.md-toc-content {
  position: relative;
  margin-left: 0;
}

.md-toc-content::after,
.md-toc::after {
  display: none;
}

.md-toc-item {
  display: block;
  color: #4183c4;
}

.md-toc-item a {
  text-decoration: none;
}

.md-toc-inner:hover {
  text-decoration: underline;
}

.md-toc-inner {
  display: inline-block;
  cursor: pointer;
}

.md-toc-h1 .md-toc-inner {
  margin-left: 0;
  font-weight: 700;
}

.md-toc-h2 .md-toc-inner {
  margin-left: 2em;
}

.md-toc-h3 .md-toc-inner {
  margin-left: 4em;
}

.md-toc-h4 .md-toc-inner {
  margin-left: 6em;
}

.md-toc-h5 .md-toc-inner {
  margin-left: 8em;
}

.md-toc-h6 .md-toc-inner {
  margin-left: 10em;
}

@media screen and (max-width: 48em) {
  .md-toc-h3 .md-toc-inner {
    margin-left: 3.5em;
  }

  .md-toc-h4 .md-toc-inner {
    margin-left: 5em;
  }

  .md-toc-h5 .md-toc-inner {
    margin-left: 6.5em;
  }

  .md-toc-h6 .md-toc-inner {
    margin-left: 8em;
  }
}

a.md-toc-inner {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.footnote-line a:not(.reversefootnote) {
  color: inherit;
}

.md-attr {
  display: none;
}

.md-fn-count::after {
  content: ".";
}

code,
pre,
samp,
tt {
  font-family: var(--monospace);
}

kbd {
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #242729;
  background-color: #fff;
  border: 1px solid #adb3b9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: rgba(12, 13, 14, 0.2) 0 1px 0, #fff 0 0 0 2px inset;
  white-space: nowrap;
  vertical-align: middle;
}

.md-comment {
  color: #a27f03;
  opacity: 0.6;
  font-family: var(--monospace);
}

code {
  text-align: left;
}

a.md-print-anchor {
  white-space: pre !important;
  border: none !important;
  display: inline-block !important;
  position: absolute !important;
  width: 1px !important;
  right: 0 !important;
  outline: 0 !important;
  text-shadow: initial !important;
  background-position: 0 0 !important;
}

.os-windows.monocolor-emoji .md-emoji {
  font-family: "Segoe UI Symbol", sans-serif;
}

.md-diagram-panel > svg {
  max-width: 100%;
}

[lang="flow"] svg,
[lang="mermaid"] svg {
  max-width: 100%;
  height: auto;
}

[lang="mermaid"] .node text {
  font-size: 1rem;
}

table tr th {
  border-bottom-width: 0;
}

video {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

iframe {
  max-width: 100%;
  width: 100%;
  border: none;
}

.highlight td,
.highlight tr {
  border: 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

.md-html-inline .md-plain,
.md-html-inline strong,
mark .md-inline-math,
mark strong {
  color: inherit;
}

.md-expand mark .md-meta {
  opacity: 0.3 !important;
}

mark .md-meta {
  color: #000;
}

@media print {
  .typora-export h1,
  .typora-export h2,
  .typora-export h3,
  .typora-export h4,
  .typora-export h5,
  .typora-export h6 {
    break-inside: avoid;
  }
}

.md-diagram-panel .messageText {
  stroke: none !important;
}

.md-diagram-panel .start-state {
  fill: var(--node-fill);
}

.md-diagram-panel .edgeLabel rect {
  opacity: 1 !important;
}

.md-fences.md-fences-math {
  font-size: 1em;
}

.md-fences-advanced:not(.md-focus) {
  padding: 0;
  white-space: nowrap;
  border: 0;
}

.md-fences-advanced:not(.md-focus) {
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: inherit;
  background-position: inherit;
  background-repeat: inherit;
}

.typora-export-show-outline .typora-export-content {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.typora-export-sidebar {
  width: 300px;
  font-size: 0.8rem;
  margin-top: 80px;
  margin-right: 18px;
}

.typora-export-show-outline #write {
  --webkit-flex: 2;
  flex: 2 1 0%;
}

.typora-export-sidebar .outline-content {
  position: fixed;
  top: 0;
  max-height: 100%;
  overflow: hidden auto;
  padding-bottom: 30px;
  padding-top: 60px;
  width: 300px;
}

@media screen and (max-width: 1024px) {
  .typora-export-sidebar,
  .typora-export-sidebar .outline-content {
    width: 240px;
  }
}

@media screen and (max-width: 800px) {
  .typora-export-sidebar {
    display: none;
  }
}

.outline-content li,
.outline-content ul {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
}

.outline-content ul {
  margin-top: 0;
  margin-bottom: 0;
}

.outline-content strong {
  font-weight: 400;
}

.outline-expander {
  width: 1rem;
  height: 1.428571429rem;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  padding-left: 4px;
}

.outline-expander::before {
  content: "";
  position: relative;
  font-family: Ionicons;
  display: inline-block;
  font-size: 8px;
  vertical-align: middle;
}

.outline-item {
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}

.outline-expander:hover::before {
  content: "";
}

.outline-h1 > .outline-item {
  padding-left: 0;
}

.outline-h2 > .outline-item {
  padding-left: 1em;
}

.outline-h3 > .outline-item {
  padding-left: 2em;
}

.outline-h4 > .outline-item {
  padding-left: 3em;
}

.outline-h5 > .outline-item {
  padding-left: 4em;
}

.outline-h6 > .outline-item {
  padding-left: 5em;
}

.outline-label {
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
}

.outline-label:hover {
  text-decoration: underline;
}

.outline-item:hover {
  border-color: #f5f5f5;
  background-color: var(--item-hover-bg-color);
}

.outline-item:hover {
  margin-left: -28px;
  margin-right: -28px;
  border-left-width: 28px;
  border-left-style: solid;
  border-left-color: transparent;
  border-right-width: 28px;
  border-right-style: solid;
  border-right-color: transparent;
}

.outline-item-single .outline-expander::before,
.outline-item-single .outline-expander:hover::before {
  display: none;
}

.outline-item-open > .outline-item > .outline-expander::before {
  content: "";
}

.outline-children {
  display: none;
}

.info-panel-tab-wrapper {
  display: none;
}

.outline-item-open > .outline-children {
  display: block;
}

.typora-export .outline-item {
  padding-top: 1px;
  padding-bottom: 1px;
}

.typora-export .outline-item:hover {
  margin-right: -8px;
  border-right-width: 8px;
  border-right-style: solid;
  border-right-color: transparent;
}

.typora-export .outline-expander::before {
  content: "+";
  font-family: inherit;
  top: -1px;
}

.typora-export .outline-expander:hover::before,
.typora-export .outline-item-open > .outline-item > .outline-expander::before {
  content: "−";
}

.typora-export-collapse-outline .outline-children {
  display: none;
}

.typora-export-collapse-outline .outline-item-open > .outline-children,
.typora-export-no-collapse-outline .outline-children {
  display: block;
}

.typora-export-no-collapse-outline .outline-expander::before {
  content: "" !important;
}

.typora-export-show-outline
  .outline-item-active
  > .outline-item
  .outline-label {
  font-weight: 700;
}

.md-inline-math-container mjx-container {
  zoom: 0.95;
}

:root {
  --side-bar-bg-color: #fafafa;
  --control-text-color: #777;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Open Sans", "Clear Sans", "Helvetica Neue", Helvetica, Arial,
    "Segoe UI Emoji", sans-serif;
  color: #333;
  line-height: 2;
}

#write {
  max-width: 860px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 1400px) {
  #write {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1800px) {
  #write {
    max-width: 1200px;
  }
}

#write > ol:first-child,
#write > ul:first-child {
  margin-top: 30px;
}

a {
  color: #4183c4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.4;
  cursor: text;
}

h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
  text-decoration: none;
}

h1 code,
h1 tt {
  font-size: inherit;
}

h2 code,
h2 tt {
  font-size: inherit;
}

h3 code,
h3 tt {
  font-size: inherit;
}

h4 code,
h4 tt {
  font-size: inherit;
}

h5 code,
h5 tt {
  font-size: inherit;
}

h6 code,
h6 tt {
  font-size: inherit;
}

h1 {
  font-size: 2.25em;
  line-height: 1.2;
  border-bottom: 1px solid #eee;
}

h2 {
  font-size: 1.75em;
  line-height: 1.225;
  border-bottom: 1px solid #eee;
}

h3 {
  font-size: 1.5em;
  line-height: 1.43;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 1em;
  color: #777;
}

blockquote,
dl,
ol,
p,
table,
ul {
  margin: 0.8em 0;
}

li > ol,
li > ul {
  margin: 0 0;
}

hr {
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
  overflow: hidden;
  box-sizing: content-box;
}

li p.first {
  display: inline-block;
}

ol:first-child,
ul:first-child {
  margin-top: 0;
}

ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

blockquote {
  border-left: 4px solid #dfe2e5;
  padding: 0 15px;
  color: #777;
}

blockquote blockquote {
  padding-right: 0;
}

table {
  padding: 0;
  word-break: initial;
}

table tr {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 0;
}

table tr:nth-child(2n),
thead {
  background-color: #f8f8f8;
}

table th {
  font-weight: 700;
  border: 1px solid #dfe2e5;
  border-bottom: 0;
  margin: 0;
  padding: 6px 13px;
}

table td {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 6px 13px;
}

table td:first-child,
table th:first-child {
  margin-top: 0;
}

table td:last-child,
table th:last-child {
  margin-bottom: 0;
}

.CodeMirror-lines {
  padding-left: 4px;
}

.code-tooltip {
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3);
  border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
  border: 1px solid #e7eaed;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 0;
  padding: 2px 4px 0 4px;
  font-size: 0.9em;
}

code {
  background-color: #f3f4f4;
  padding: 0 2px 0 2px;
}

.md-fences {
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 6px;
}

.md-task-list-item > input {
  margin-left: -1.3em;
}

@media print {
  html {
    font-size: 13px;
  }

  pre,
  table {
    page-break-inside: avoid;
  }

  pre {
    word-wrap: break-word;
  }
}

.md-fences {
  background-color: #f8f8f8;
}

#write pre.md-meta-block {
  padding: 1rem;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f7f7f7;
  border: 0;
  border-radius: 3px;
  color: #777;
  margin-top: 0 !important;
}

.mathjax-block > .code-tooltip {
  bottom: 0.375rem;
}

.md-mathjax-midline {
  background: #fafafa;
}

#write > h3.md-focus:before {
  left: -1.5625rem;
  top: 0.375rem;
}

#write > h4.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

#write > h5.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

#write > h6.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

.md-image > .md-meta {
  border-radius: 3px;
  padding: 2px 0 0 4px;
  font-size: 0.9em;
  color: inherit;
}

.md-tag {
  color: #a7a7a7;
  opacity: 1;
}

.md-toc {
  margin-top: 20px;
  padding-bottom: 20px;
}

.sidebar-tabs {
  border-bottom: none;
}

#typora-quick-open {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
}

#typora-quick-open-item {
  background-color: #fafafa;
  border-color: #fefefe #e5e5e5 #e5e5e5 #eee;
  border-style: solid;
  border-width: 1px;
}

.on-focus-mode blockquote {
  border-left-color: rgba(85, 85, 85, 0.12);
}

.context-menu,
.megamenu-content,
footer,
header {
  font-family: "Segoe UI", Arial, sans-serif;
}

.file-node-content:hover .file-node-icon,
.file-node-content:hover .file-node-open-state {
  visibility: visible;
}

.mac-seamless-mode #typora-sidebar {
  background-color: #fafafa;
  background-color: var(--side-bar-bg-color);
}

.md-lang {
  color: #b4654d;
}

#md-notification .btn {
  border: 0;
}

.dropdown-menu .divider {
  border-color: #e5e5e5;
  opacity: 0.4;
}

.ty-preferences .window-content {
  background-color: #fafafa;
}

.ty-preferences .nav-group-item.active {
  color: #fff;
  background: #999;
}

.menu-item-container a.menu-style-btn {
  background-color: #f5f8fa;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.8),
    hsla(0, 0%, 100%, 0)
  );
}

@media print {
  @page {
    margin: 0;
  }

  body.typora-export {
    padding-left: 0;
    padding-right: 0;
  }

  #write {
    padding: 0;
  }
}
</style>
