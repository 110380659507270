<!-- android端隐私协议 -->

<template>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>隐私协议</title>
  </head>

  <div id="write" class="first-line-indent">
    <p>更新日期：2024年10月08日<br />发布日期：2024年10月08日</p>

    <p>
      二十四小时买卖网（大连）科技有限公司（以下简称“我司”）非常重视用户个人信息及隐私权的保护，
      因此我们制订了涵盖如何收集、存储、使用、共享和保护用户信息以及我们为您提供的访问、更新、删除和保护这些信息方式的隐私政策。我们希望通过本隐私权保护声明（以下简称“本隐私政策”）向您清晰地介绍我们对您个人信息及其他信息的处理方式。<br />
      本隐私政策与您使用24小时买卖网 平台
      的产品和服务息息相关（以下简称“24小时买卖网”）
      请您在使用24小时买卖网的所有产品和服务前，完整阅读并透彻理解《24小时买卖网隐私政策》并确定了解我们对您个人信息及其他信息的处理规则，并相应作出您认为适当的选择。<br />
      如果您不同意本隐私政策的任何内容，意味着您选择放弃使用24小时买卖网产品和服务，亦请立即停止使用24小时买卖网产品和服务的行为。当您使用24小时买卖网任一产品和服务时，则表示您同意且完全理解本隐私政策的全部内容。我们将在隐私政策中逐项说明相关情况，请特别关注。<br />
      除本隐私政策外，在特定场景下，我们还会通过即时告知（含弹窗、页面提示、小红点提示）、功能更新说明方式，向你说明对应的信息收集目的、范围及使用方式，这些即时告知及功能更新说明构成本隐私政策的一部分，并与本隐私政策具有同等效力。<br />下文将帮你详细了解我们如何收集、使用、存储、传输、公开与保护个人信息；帮你了解个人信息的处理方式。我们将在隐私政策中逐项说明相关情况，请特别关注。
    </p>
    <p>
      <strong>1.信息收集范围</strong>
      <br />
      <strong>2.我们如何收集信息</strong>
      <br />
      <strong>3.我们如何使用信息</strong>
      <br />
      <strong>4.第三方合作伙伴共享、转让和公开披露</strong>
      <br />
      <strong>5.我们如何存储和保护个人信息</strong>
      <br />
      <strong>6.未成年人保护</strong>
      <br />
      <strong>7.管理你的个人信息</strong>
      <br />
      <strong>8.服内容的变更</strong>
      <br />
      <strong>9.隐私政策的查阅和修订</strong>
      <br />
      <strong>10.联系我们</strong>
    </p>
    <p>
      <strong>1 . 信息收集范围</strong>
      <br />
      1.1
      当你注册、登录、使用相关服务时，我们将获取您的手机号、ip等信息，用于生成24小时买卖网账号，使你可以直接注册、登录并使用APP的相关服务<br />

      1.2
      为提供持续服务和保证服务质量所需，我们在您使用本产品期间，将记录并保存您登录和使用本服务的信息，包括软硬件特征信息、用户标识符。<br />
      
      <strong>2.如何收集信息</strong>
      <br />
      2.1
      我们通过您主动提交，以及在您使用我们的服务过程中产生相应的记录方式收集您的信息。<br />
      2.2
      我们致力于为你提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。为了维护相关产品或服务的正常稳定运行，保护你或其他用户或公众的安全及合法利益，我们在您同意的前提下，应用将在每次启动时收集这些信息必要信息：<br />
      <strong
        >（1）为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、设备标识符（如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、网络设备硬件地址（MAC地址）、硬件序列号、IP地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、传感器信息数据。</strong
      >
      <br />
      （2）为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行中的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。<br />
      （3）我们可能使用你的账号信息、设备信息、服务日志信息，以及我们的关联方、合作方获得你授权或依法可以提供给我们的信息，用于判断账号安全、交易安全、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。<br />
      2.3 Cookie等同类技术的使用<br />
      Cookie
      和设备信息标识等同类技术是互联网中普遍使用的技术。当你使用本及相关服务时，我们可能会使用相关技术向你的设备发送一个或多个Cookie
      或匿名标识符，以收集、标识你访问、使用本产品时的信息。我们承诺，不会将Cookie
      用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie
      和同类技术主要为了实现以下功能或服务：<br />
      保障产品与服务的安全、高效运转：我们可能会设置认证与保障安全性的Cookie
      或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。<br />
      帮助你获得更轻松的访问体验：使用此类技术可以帮助你省去重复填写个人信息、输入搜索内容的步骤和流程（示例：保存组件中的信息、表单提交）。<br />
      在内容的分享页中，我们可能会使用Cookie对浏览活动进行记录，用于排查崩溃、延迟的相关异常情况以及探索更好的服务方式。<br />
      Cookie的清除
      ：你可以在APP设置--&gt;更多--&gt;关于24小时买卖网中操作清除Cookie数据，但清除后可能无法使用由我们提供的、依赖于Cookie的功能或服务。<br />
      2.4 依法豁免征得同意处理个人信息<br />
      请你理解，在下列情形中，根据法律法规，我们处理你的个人信息无需征得你的授权同意。<br />
      a) 根据你的要求订立或履行合同所必需；<br />
      b)
      为履行法定职责或者法定义务所必需，例如与国家安全、国防安全、与刑事侦查、起诉、审判和判决执行等直接相关的法定职责或者法定义务；<br />
      c)
      为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br />
      d) 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；<br />
      e)
      在合理的范围内处理你自行公开的个人信息，或者其他已经合法公开的个人信息（如合法的新闻报道、政府信息公开等渠道合法公开的个人信息）；<br />
      f) 法律法规规定的其他情形。<br />
      特别说明：根据法律规定，如信息无法单独或结合其他信息识别到特定个人的，其不属于个人信息。当你的信息可以单独或结合其他信息识别到你时，或我们把没有与任何特定个人建立联系的数据与你的个人信息结合使用时，我们会将其作为你的个人信息，按照本隐私政策进行处理与保护。<br />
      <strong>3.我们如何使用信息</strong>
      <br />
      3.1 信息的使用<br />
      为了能够正常向您提供小组件的相关功能，我们会收集您的相关设备信息，在您同意前提下，应用将在每次使用相关功能或下列场景下收集这些信息，这些信息包括：<br />
      <strong
        >3.1.1
        设备属性：如：手机型号，系统版本，网络类型，电池电量，可用存储空间，应用和文件名称及类型，当前应用版本号信息，内存使用情况，CPU信息（频率、型号、架构）传感信息，设备生产商。</strong
      >

      <br />
      <strong
        >3.1.2
        位置信息：为展示你所在地区（城市、区县）的商品相关信息，需收集使用你的地理位置信息。地理位置信息包括：通过网络位置信息（例如
        IP 及 WLAN）获取的大致地理位置信息；通过 GPS 信息、WLAN
        接入点、蓝牙和基站信息获取到的精确地理位置信息。精确地理位置信息仅在你开启地理位置权限后才会被获取。</strong
      >
      <br />
      <strong
        >3.1.3 相册/相机:
        当您在使用更换头像等功能时我们会读取存储中的图片、文件、音频、视频内容，主要用于帮助您上传小组件背景图、封面图片、文件、音频、视频信息功能。</strong
      >
      <br />
      <strong
        >3.1.4 日志信息
        ：在软件与服务的安全运行过程中，我们会收集你的设备信息和日志信息。设备信息指设备型号、网络标识符、网络接入信息与设备性能相关的信息，在手机终端中可能被表述为“电话信息”，这不代表
        App
        会收集通讯内容或您主动输入的隐私信息；日志信息指在你使用服务的过程中生成的服务日志。</strong
      >
      <br />
      <strong>3.1.5 文件存储</strong>
      <br />
      <strong
        >允许App或小组件读取或存储中的图片、文件内容，主要用于帮助您上传用户头像、文件、下载商品图片信息等功能。</strong
      >
      <br />
      <strong
        >3.1.6
        联系方式：当您再在APP使用过程中遇到问题需要反馈信息给我们时，我们会收集您的电话/箱/QQ/微信/其它信息（可选），以便我们产品团队更好的定位和解决您遇到的问题。</strong
      >
    

      <br />
      <strong
        >3.1.7
        剪切板：在你分享或接收被分享的信息时，需要在本地访问你的剪切板，读职其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。</strong
      >

     
      <!-- <br />
      <strong>3.1.8 广告推送：向你发布商品或服务的推广信息</strong>
      <br />
      <strong
        >我们会向你发布商品或服务的推广信息（广告），我们会收集使用信息、IP地址、MAC地址、位置信息
        IMEI、系统类型、系统版本、网络环境、加速度传感器、陀螺仪传感器，根据你的喜好进行推荐的信息，也包括不具有特定性的普遍发布的信息。我们可能与进行推广和广告投放的合作伙伴共享信息，但我们会采取必要的技术手段，在不识别你个人身份的前提下向你提供更为相关的推广信息</strong
      >
      <br />
      <strong
        >我们可能与业务的服务商、供应商和其他合作伙伴共享群体画像或统计信息，这些信息难以或无法与你的真实身份相关联。这些信息将帮助我们分析、衡量广告和相关服务的有效性。</strong
      > -->
      
      <br />
      <strong
        >3.1. 其他场景
        其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的关联性，我们会在使用信息前重新征得您的同意。</strong
      >
      <br />
      <strong>4 第三方共享、转让和公开披露</strong>
      <br />
      未经您同意，我们不会与任何无关第三方分享您的信息，以下情形除外：<br />
      我们可能将您的信息与我们的关联公司、第三方服务提供商、承包商及代理分享，仅用作下列用途：<br />
      4.1
      提供“1.信息收集范围”中的相应功能或服务所必需，以及出于“3.信息使用”中的部分所述目的所必需。<br />
      4.1.2履行我们在<a href="https://qqspmmw.com/user">《用户使用协议》</a>
      或本声明中的义务和行使我们的权利。<br />
      4.1.3
      如我们与任何上述第三方分享您的信息，我们将努力确保第三方在使用您的信息时遵守本声明及我们要求其遵守的其他适当的保密和安全措施。<br />
      4.1.4
      为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序，我们与合作方合作过程中，<br />
      将遵守以下原则：<br />
      a) 合法原则：涉及数据处理活动的，应当遵守合法原则。<br />
      b)
      正当与最小必要原则：数据使用必须具有正当目的，且应以达成目的必要为限。<br />
      c) 安全审慎原则：
      我们将审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。<br />
      d）我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息，<a
        href="https://qqspmmw.com/sdk"
        >点击查看我们接入第三方授权合作伙伴的SDK详情。</a
      >
      <br />
      4.2 委托处理<br />
      对于委托处理个人信息的场景，我们会与受托合作方根据法律规定签署相关处理协议，并对其个人信息使用活动进行监督。<br />
      4.3共同处理<br />
      对于共同处理个人信息的场景，我们会与合作方根据法律规定签署相关协议并约定各自的权利和义务，确保在使用相关个人信息的过程中遵守法律的相关规定、保护数据安全。<br />
      4.4 合作方的范围<br />
      若具体功能和场景中涉及由我们的关联方、第三方提供服务，则合作方范围包括我们的关联方与第三方。<br />
      4.5合作的场景<br />
      （1）消息通知：为与你使用的终端机型适配消息推送功能，终端设备制造商（华为、小米、OPPO、VIVO、荣耀、魅族）可能通过SDK等技术使用你的手机型号、版本及相关设备信息。<br />
      （2）登录、绑定其他第三方账号：当你使用微信账号登录我们产品或服务时，经过你的同意，上述第三方的产品或服务将会使用你的昵称、头像及其他你授权的信息。<br />
      （3）活动参与：当你选择参加我们举办的营销活动时，经过你的同意，我们与合作方可能需要使用你的姓名、通信地址、联系方式、信息，以便合作方向你兑现活动奖励。当你参与我们和关联方共同举办的营销活动时，可能会在多个参与活动的移动应用程序（简称“活动平台”）之间同步你参与活动的账号（含标识符）、参与成绩、进度、收益信息，以保障你在活动中获得完整的服务体验。<br />
      （4）客户服务：为及时处理你的投诉、建议以及其他诉求，客户服务提供商需要使用你的账号及相关信息，以及时了解、处理和响应你的需求。如有必要，也可能需要使用你的手机号码或通过其他通讯方式与你取得联系。<br />
      （5）支付服务：当您开通会员时我们会使用支付服务，支付功能由与我们合作的第三方支付机构向你提供服务。第三方支付机构可能需要收集你的支付信息、如：有效期及手机号码、和微信支付、支付宝、银行卡相关信息是个人敏感信息，这些信息是支付功能所必需的信息，拒绝提供将导致你无法使用该功能，但不影响其他功能的正常使用。<br />
      （6）广告：我们可能与广告的服务商、供应商和其他合作伙伴（合称“广告合作方”）共享分析去标识化的设备信息或统计信息，这些信息难以或无法与你的真实身份相关联。这些信息将帮助我们分析、衡量广告和相关服务的有效性。<br />
      a）广告推送与投放：进行推送/推广和广告投放或提供相关服务的广告合作方可能需要使用去标识化或匿名化后的设备、网络、渠道信息以及标签信息；与广告主合作的广告推送/推广、投放/分析服务的广告合作方可能需要使用前述信息，以实现广告投放、优化与提升广告有效触达率。<br />
      b)
      广告统计分析：提供广告统计分析服务的广告合作方可能需要使用你的设备、网络、广告点击、浏览、展示以及广告转化数据信息用于分析、衡量、提升广告和相关服务的有效性。<br />
      c)
      广告合作方对信息的使用：广告合作方可能将上述信息与其合法获取的其他数据相结合，以优化广告投放效果，我们会要求其对信息的使用遵循合法、正当、必要原则，保障用户合法权利不受侵犯。<br />
      d)
      广告留资信息：你在广告中主动填写、提交的联系方式、地址相关信息，可能被广告主或其委托的合作方收集并使用。<br />
      （7）实现安全与统计分析<br />
      a)
      保障使用安全：我们非常重视产品和服务的安全性，为保障用户的正当合法权益免受不法侵害，我们的合作方可能会使用必要的设备、账号及日志信息。<br />
      b）分析产品情况：为分析产品和服务的稳定性，提供分析服务的合作方可能需要使用服务情况（崩溃、闪退记录）、设备标识信息、应用总体安装使用情况的信息。<br />
      （8）一键分享服务：为了向您提供一键分享服务，我们的产品集成友盟+ U-Share
      SDK，将收集您的设备标识信息（如IMEI/android
      ID/IDFA）和您需要分享的社交账户公开信息，以便完成一键分享服务。为了您的信息安全，我们已与第三方SDK服务商进行数据安全保密约定，这些公司会严格遵守我们的数据隐私和安全要求。除非得到您的同意，我们不会与其共享您的个人身份信息。为便于您更好地了解【友盟+】采集的数据类型及用途，以及何保护您的个人信息，您可以登陆https://www.umeng.com/policy了解【友盟+】隐私权政策。”<br />
      <strong>5.我们如何存储和保护个人信息</strong>
      <br />
      5.1 信息存储<br />
      5.1.1
      我们依照法律法规的规定，将在中华人民共和国境内运营过程中收集和产生的你的个人信息存储于境内。我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求你的同意。<br />
      5.1.2 存储期限<br />
      a) 我们仅会在为你提供产品或服务所必需的期间内保留你的个人信息，例如：<br />
      你发布的内容及相关信息，在你未撤回、删除或未注销账号之前，我们会予以保留用于向你提供持续的业务功能。<br />
      b)
      如果你注销账号、主动删除个人信息或信息超出必要的保存期限后，我们将对你的个人信息进行删除或匿名化处理，但以下情况除外：<br />
      遵从法律法规有关信息留存的要求（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。<br />
      出于财务、审计、争议解决等目的需要合理延长期限的。<br />
      5.1.3
      通常，我们仅在为您提供服务期间保留您的信息，保留时间不会超过满足相关使用目的所必须的时间。
      但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：<br />
      遵守适用的法律法规等有关规定。<br />
      a)遵守法院判决、裁定或其他法律程序的要求。<br />
      b)遵守公安机关、政府机关或其他有权机关的要求。<br />
      c)我们有理由确信需遵守法律法规等有关规定。<br />
      d)为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。<br />
      5.2 信息安全<br />
      5.2.1
      我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。<br />
      我们使用各种安全技术以保障信息的安全。例如，我们将通过服务器多备份、密码加密安全措施，防止信息泄露、毁损、丢失。<br />
      5.2.2
      我们会建立专门的安全部门、安全管理制度、数据安全流程保障你的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问你的个人信息。我们会定期对人员进行安全教育和培训，并适时对数据和技术进行安全审计。<br />
      我们会制定并组织实施个人信息安全事件应急预案，并在发生个人信息安全事件时立即启动应急预案，努力控制这些安全事件的影响，防止不利后果扩大。一旦发生或可能发生个人信息安全事件（泄露、篡改、丢失）的，我们将按照法律法规的要求，及时向你告知以下情况：发生或者可能发生泄露、篡改、丢失的个人信息种类、原因和可能造成的危害，我们已采取的补救措施，你可以采取的减轻危害的措施以及与我们联系的方式。<br />
      我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知你，难以逐一告知时，我们会采取合理、有效的方式发布公告。<br />
      同时，我们还将按照相关监管部门要求，上报个人信息安全事件的处置情况。<br />
      5.2.3
      我们重视信息安全合规工作，并通过众多国际和国内的安全认证充分保障您的信息安全。<br />
      但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网环境下，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。您同意此种情况下对我们予以免责。<br />
      若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》有关规定及时上报，并以发送邮件、推送通知、公告形式告知您相关情况，并向您给出安全建议。<br />
      5.2.4
      为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识。我们仅在服务直接导致您个人信息泄露的范围内承担责任，因此，请您妥善保管您的账号及密码信息，避免您的个人信息泄露。除非您判断认为必要的情形下，不向任何第三人提供您的账号密码等个人信息。<br />
      <strong>6 未成年人保护</strong>
      <br />
      我们非常重视未成年人的个人信息保护<br />
      6.1我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。<br />
      若您是未成年人的父母或其他监护人，请您关注未成年人是否是在取得您的授权同意之后使用我们的服务。<br />
      6.2如果您未能取得您监护人的同意或您未同意您的被监护人使用我们的服务并向我们提供信息，请您或被监护人立即停止使用我们的服务并及时与我们取得联系。<br />
      在收到您的书面通知而知晓，我们在未事先获得监护人同意的情况下收集了未成年的个人信息时，我们会立即停止相关信息收集及使用。<br />
      <strong>7.管理你的个人信息</strong>
      <br />
      我们非常重视你对个人信息的管理，并尽全力保护你对于你个人信息的查阅、复制、更正、补充、删除、撤回同意授权、注销账号、投诉举报的权利，以使你有能力了解并保障你的个人信息安全。<br />
      但请你理解，特定的业务功能和服务将需要你的信息才能得以完成，当你撤回同意或授权后，我们无法继续为你提供对应的功能和服务，也不再处理你相应的个人信息。但你撤回同意或授权的决定，不会影响我们此前基于你的授权而开展的个人信息处理。<br />
      我们在不同的版本或操作系统中提供的管理方式的路径可能有差异，请以实际为准。<br />
      7.1 系统权限设置<br />
      a)
      你可以在设备的设置功能中开启或关闭位置信息、存储、相册、权限，改变授权范围或撤回你的授权。撤回授权后我们将不再收集与这些权限相关的信息，但不会影响我们此前基于你的授权而开展的个人信息处理。你可以阅读<a
        href="https://qqspmmw.com/permissions"
        >《权限申请与使用情况说明》</a
      >
      说明，以了解我们申请和使用系统权限的情况。<br />
      b)
      你可以在手机系统设置--&gt;应用程序信息--&gt;权限列表--&gt;选择是否接收APP发送的系统通知，或设置你希望接收的系统通知的形态。<br />
      c)如果你不希望我们访问你的相册来帮助你实现更换个人头像功能，你可以在手机系统设置--&gt;应用程序信息--&gt;权限列表--&gt;关闭相册存储的授权。<br />
      d)如果你不希望我们的首页获取你的地理位置，你可以在手机系统设置--&gt;应用程序信息--&gt;权限列表--&gt;关闭地理位置授权。<br />
      7.2
      您可以在使用我们服务的过程中，访问、修改和删除您的相关信息。您访问、修改和删除信息的方式将取决于您使用的具体服务。<br />
      7.3
      如您发现我们违反法律法规的规定或者双方的约定收集、使用您的信息，您可以要求我们删除。如您发现我们收集、存储的您的信息有错误的，且无法自行更正的，您也可以要求我们更正。<br />
      7.4
      在访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账户安全。请您理解，由于技术所限、基于法律法规要求，您的某些请求可能无法进行响应。<br />
      出于安全性和身份识别的考虑，你可能无法自主修改注册时提交的某些初始注册信息；如你确有必要修改该类信息，请你通过电子邮件（
      <a href="mailto:developer@qqspmmw.com">developer@qqspmmw.com</a>
      ）联系我们，我们将尽快审核所涉问题，并由专人验证你的用户身份后15个工作日予以回复。<br />
      7.5
      基于法律法规要求、保障信息安全正当事由，您的部分信息可能无法访问、修改和删除。<br />
      7.6
      您可以通过隐私政策列明的联系方式联系或打开APP--&gt;我的--&gt;设置--&gt;注销账号，来完成注销流程。我们在接收到您的请求后，您账号的数据都将会在24小时内被清空，该操作无法恢复。<br />
      敬请您注意并知悉，您在注销前自行备份帐号相关的所有信息，并请确认与本帐号相关的所有服务均已进行妥善处理。注销完成后，我们将删除您的个人信息或对其进行匿名化处理。请您知悉并理解，根据相关法律法规规定相关日志记录将保留不少于6个月的时间。<br />
      <strong>8 服务内容的变更</strong>
      <br />
      请你了解，随着我们业务的发展，可能会调整提供的产品或服务。如调整变化后的产品或服务所处理个人信息的目的、方式和范围发生变化的，我们会再次向你告知，并根据法律法规的相关要求征得你的同意。<br />
      8.1
      随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的信息有可能作为此类交易的一部分而被转移。我们将遵守相关法律法规的要求，在转移前通知您，确保信息在转移时的机密性，以及变更后继续履行相应责任和义务。<br />
      8.2 我们还可能因以下原因而披露您的信息：<br />
      a）遵守适用的法律法规等有关规定。<br />
      b）遵守法院判决、裁定或其他法律程序的规定。<br />
      c） 遵守公安机关、政府机关或其他有权机关的要求。<br />
      d） 我们有理由确信需遵守法律法规等有关规定。<br />
      e）
      为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。<br />
      f）经过您合法授权的情形。<br />
      如我们因上述原因而披露您的信息，我们将在遵守法律法规相关规定及本声明的基础上及时告知您。<br />
      <strong>9 隐私政策的查阅和修订</strong>
      <br />
      9.1 查阅<br />
      a.你可以在首次进入APP，或者在APP
      --&gt;更多--&gt;关于24小时买卖网--&gt;隐私政策，页面查看本隐私政策。<br />
      b.请你了解，为了满足运营需要，我们可能会根据你所使用的手机型号、系统版本、移动客户端因素开发不同的版本，因此在你使用特定版本时，APP的功能可能与本隐私政策描述的有所区别，但不会影响数据处理的目的、方式和范围，具体可用功能以你实际使用的情况为准。<br />
      9.2 更新及通知<br />
      a.为了给你提供更好的服务，我们产品和服务将不时更新与变化，我们会适时对本隐私政策进行修订。未经你明确同意，我们不会削减你依据当前生效的隐私政策所应享受的权利。<br />
      b.本隐私政策更新后，我们会在APP内发出更新版本，并通过站内信或其他适当的方式提醒你更新的内容，以便你及时了解本隐私政策的最新版本。<br />
      c.我们也可能通过功能更新，帮助你更好地管理你的个人信息，请你留意相关的功能说明。<br />
      <strong>10 联系我们</strong>
      <br />
      我们设立了专门的个人信息保护团队和个人信息保护负责人，如果您对本隐私政策或个人信息保护相关事宜有任何疑问或投诉、举报、建议时，您可以通过以下方式与我们联系：<br />
      a）发送邮件至：<a href="mailto:developer@qqspmmw.com"
        >developer@qqspmmw.com</a
      >
      邮箱。<br />
      b）邮寄至： 辽宁省大连市沙河口区长兴街139号13层3号 法务部<br />我们将尽快审核你提出的问题或建议，并在验证你的用户身份后的15个工作日内回复。
    </p>
    <p>
      公司名称：二十四小时买卖网（大连）科技有限公司<br />
      统一社会信用代码：91210204MA11807P2Q<br />
      地址：中国辽宁省大连市沙河口区长兴街139号13层3号<br />
      联系电话: 0411-84641106<br />
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyAgreementAndroid",
  data() {
    return {};
  },
};
</script>

<style scoped>
html {
  overflow-x: initial !important;
}

:root {
  --bg-color: #ffffff;
  --text-color: #0f0f0f;
  --select-text-bg-color: #b5d6fc;
  --select-text-font-color: auto;
  --monospace: "Lucida Console", Consolas, "Courier", monospace;
  --title-bar-height: 20px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #272727;
    --text-color: #e8e8e8;
  }
}

.mac-os-11 {
  --title-bar-height: 28px;
}

html {
  font-size: 14px;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
  height: auto;
  inset: 0;
  font-size: 1rem;
  overflow-x: hidden;
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: inherit;
  tab-size: 4;
  background-position: inherit;
  background-repeat: inherit;
}

iframe {
  margin: auto;
}

a.url {
  word-break: break-all;
}

a:active,
a:hover {
  outline: 0;
}

.in-text-selection,
::selection {
  text-shadow: none;
  background: var(--select-text-bg-color);
  color: var(--select-text-font-color);
}

#write {
  margin: 0 auto;
  height: auto;
  width: inherit;
  word-break: normal;
  word-wrap: break-word;
  position: relative;
  white-space: normal;
  overflow-x: visible;
  padding-top: 36px;
}

#write.first-line-indent li p,
#write.first-line-indent p * {
  text-indent: 0;
}

#write.first-line-indent li {
  margin-left: 2em;
}

.for-image #write {
  padding-left: 8px;
  padding-right: 8px;
}

body.typora-export {
  padding-left: 30px;
  padding-right: 30px;
}

.typora-export .footnote-line,
.typora-export li,
.typora-export p {
  white-space: pre-wrap;
}

.typora-export .task-list-item input {
  pointer-events: none;
}

@media screen and (max-width: 500px) {
  body.typora-export {
    padding-left: 0;
    padding-right: 0;
  }

  #write {
    padding-left: 20px;
    padding-right: 20px;
  }

  .CodeMirror-sizer {
    margin-left: 0 !important;
  }

  .CodeMirror-gutters {
    display: none !important;
  }
}

#write li > figure:last-child {
  margin-bottom: 0.5rem;
}

#write ol,
#write ul {
  position: relative;
}

img {
  max-width: 100%;
  vertical-align: middle;
  image-orientation: from-image;
}

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-variant-caps: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  line-height: inherit;
}

input[type="checkbox"],
input[type="radio"] {
  line-height: normal;
  padding: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p,
#write pre {
  width: inherit;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p {
  position: relative;
}

p {
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  break-after: avoid-page;
  break-inside: avoid;
  orphans: 4;
}

p {
  orphans: 4;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1rem;
}

.md-math-block,
.md-rawblock,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hidden {
  display: none;
}

.md-blockmeta {
  color: #ccc;
  font-weight: 700;
  font-style: italic;
}

a {
  cursor: pointer;
}

sup.md-footnote {
  padding: 2px 4px;
  background-color: rgba(238, 238, 238, 0.7);
  color: #555;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
}

sup.md-footnote a,
sup.md-footnote a:hover {
  color: inherit;
  text-transform: inherit;
  text-decoration: inherit;
}

#write input[type="checkbox"] {
  cursor: pointer;
  width: inherit;
  height: inherit;
}

figure {
  overflow-x: auto;
  margin: 1.2em 0;
  max-width: calc(100% + 16px);
  padding: 0;
}

figure > table {
  margin: 0;
}

tr {
  break-inside: avoid;
  break-after: auto;
}

thead {
  display: table-header-group;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow: auto;
  break-inside: auto;
  text-align: left;
}

table.md-table td {
  min-width: 32px;
}

.CodeMirror-gutters {
  border-right-width: 0;
  background-color: inherit;
}

.CodeMirror {
  text-align: left;
}

.CodeMirror-placeholder {
  opacity: 0.3;
}

.CodeMirror pre {
  padding: 0 4px;
}

.CodeMirror-lines {
  padding: 0;
}

div.hr:focus {
  cursor: none;
}

#write pre {
  white-space: pre-wrap;
}

#write.fences-no-line-wrapping pre {
  white-space: pre;
}

#write pre.ty-contain-cm {
  white-space: normal;
}

.CodeMirror-gutters {
  margin-right: 4px;
}

.md-fences {
  font-size: 0.9rem;
  display: block;
  break-inside: avoid;
  text-align: left;
  overflow: visible;
  white-space: pre;
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: inherit;
  position: relative !important;
  background-position: inherit;
  background-repeat: inherit;
}

.md-fences-adv-panel {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 8px;
  overflow-x: auto;
}

#write .md-fences.mock-cm {
  white-space: pre-wrap;
}

.md-fences.md-fences-with-lineno {
  padding-left: 0;
}

#write.fences-no-line-wrapping .md-fences.mock-cm {
  white-space: pre;
  overflow-x: auto;
}

.md-fences.mock-cm.md-fences-with-lineno {
  padding-left: 8px;
}

.CodeMirror-line,
twitterwidget {
  break-inside: avoid;
}

.footnotes {
  opacity: 0.8;
  font-size: 0.9rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.footnotes + .footnotes {
  margin-top: 0;
}

.md-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
  text-decoration: none;
  text-shadow: none;
  float: none;
  position: static;
  width: auto;
  height: auto;
  white-space: nowrap;
  cursor: inherit;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  box-sizing: content-box;
  direction: ltr;
  background-position: 0 0;
}

li div {
  padding-top: 0;
}

blockquote {
  margin: 1rem 0;
}

li .mathjax-block,
li p {
  margin: 0.5rem 0;
}

li blockquote {
  margin: 1rem 0;
}

li {
  margin: 0;
  position: relative;
}

blockquote > :last-child {
  margin-bottom: 0;
}

blockquote > :first-child,
li > :first-child {
  margin-top: 0;
}

.footnotes-area {
  color: #888;
  margin-top: 0.714rem;
  padding-bottom: 0.143rem;
  white-space: normal;
}

#write .footnote-line {
  white-space: pre-wrap;
}

@media print {
  body,
  html {
    border: 1px solid transparent;
    height: 99%;
    break-after: avoid;
    break-before: avoid;
    font-variant-ligatures: no-common-ligatures;
  }

  #write {
    margin-top: 0;
    padding-top: 0;
    border-color: transparent !important;
  }

  .typora-export * {
    -webkit-print-color-adjust: exact;
  }

  .typora-export #write {
    break-after: avoid;
  }

  .typora-export #write::after {
    height: 0;
  }

  .is-mac table {
    break-inside: avoid;
  }

  .typora-export-show-outline .typora-export-sidebar {
    display: none;
  }
}

.footnote-line {
  margin-top: 0.714em;
  font-size: 0.7em;
}

a img,
img a {
  cursor: pointer;
}

pre.md-meta-block {
  font-size: 0.8rem;
  min-height: 0.8rem;
  white-space: pre-wrap;
  background-color: #ccc;
  display: block;
  overflow-x: hidden;
}

p > .md-image:only-child:not(.md-img-error) img,
p > img:only-child {
  display: block;
  margin: auto;
}

#write.first-line-indent p > .md-image:only-child:not(.md-img-error) img {
  left: -2em;
  position: relative;
}

p > .md-image:only-child {
  display: inline-block;
  width: 100%;
}

#write .MathJax_Display {
  margin: 0.8em 0 0;
}

.md-math-block {
  width: 100%;
}

.md-math-block:not(:empty)::after {
  display: none;
}

.MathJax_ref {
  fill: currentcolor;
}

[contenteditable="false"]:active,
[contenteditable="false"]:focus,
[contenteditable="true"]:active,
[contenteditable="true"]:focus {
  outline: 0;
  box-shadow: none;
}

.md-task-list-item {
  position: relative;
  list-style-type: none;
}

.task-list-item.md-task-list-item {
  padding-left: 0;
}

.md-task-list-item > input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -1.2em;
  margin-top: calc(1em - 10px);
  border: none;
}

.math {
  font-size: 1rem;
}

.md-toc {
  min-height: 3.58rem;
  position: relative;
  font-size: 0.9rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.md-toc-content {
  position: relative;
  margin-left: 0;
}

.md-toc-content::after,
.md-toc::after {
  display: none;
}

.md-toc-item {
  display: block;
  color: #4183c4;
}

.md-toc-item a {
  text-decoration: none;
}

.md-toc-inner:hover {
  text-decoration: underline;
}

.md-toc-inner {
  display: inline-block;
  cursor: pointer;
}

.md-toc-h1 .md-toc-inner {
  margin-left: 0;
  font-weight: 700;
}

.md-toc-h2 .md-toc-inner {
  margin-left: 2em;
}

.md-toc-h3 .md-toc-inner {
  margin-left: 4em;
}

.md-toc-h4 .md-toc-inner {
  margin-left: 6em;
}

.md-toc-h5 .md-toc-inner {
  margin-left: 8em;
}

.md-toc-h6 .md-toc-inner {
  margin-left: 10em;
}

@media screen and (max-width: 48em) {
  .md-toc-h3 .md-toc-inner {
    margin-left: 3.5em;
  }

  .md-toc-h4 .md-toc-inner {
    margin-left: 5em;
  }

  .md-toc-h5 .md-toc-inner {
    margin-left: 6.5em;
  }

  .md-toc-h6 .md-toc-inner {
    margin-left: 8em;
  }
}

a.md-toc-inner {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.footnote-line a:not(.reversefootnote) {
  color: inherit;
}

.md-attr {
  display: none;
}

.md-fn-count::after {
  content: ".";
}

code,
pre,
samp,
tt {
  font-family: var(--monospace);
}

kbd {
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #242729;
  background-color: #fff;
  border: 1px solid #adb3b9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: rgba(12, 13, 14, 0.2) 0 1px 0, #fff 0 0 0 2px inset;
  white-space: nowrap;
  vertical-align: middle;
}

.md-comment {
  color: #a27f03;
  opacity: 0.6;
  font-family: var(--monospace);
}

code {
  text-align: left;
}

a.md-print-anchor {
  white-space: pre !important;
  border: none !important;
  display: inline-block !important;
  position: absolute !important;
  width: 1px !important;
  right: 0 !important;
  outline: 0 !important;
  text-shadow: initial !important;
  background-position: 0 0 !important;
}

.os-windows.monocolor-emoji .md-emoji {
  font-family: "Segoe UI Symbol", sans-serif;
}

.md-diagram-panel > svg {
  max-width: 100%;
}

[lang="flow"] svg,
[lang="mermaid"] svg {
  max-width: 100%;
  height: auto;
}

[lang="mermaid"] .node text {
  font-size: 1rem;
}

table tr th {
  border-bottom-width: 0;
}

video {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

iframe {
  max-width: 100%;
  width: 100%;
  border: none;
}

.highlight td,
.highlight tr {
  border: 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

.md-html-inline .md-plain,
.md-html-inline strong,
mark .md-inline-math,
mark strong {
  color: inherit;
}

.md-expand mark .md-meta {
  opacity: 0.3 !important;
}

mark .md-meta {
  color: #000;
}

@media print {
  .typora-export h1,
  .typora-export h2,
  .typora-export h3,
  .typora-export h4,
  .typora-export h5,
  .typora-export h6 {
    break-inside: avoid;
  }
}

.md-diagram-panel .messageText {
  stroke: none !important;
}

.md-diagram-panel .start-state {
  fill: var(--node-fill);
}

.md-diagram-panel .edgeLabel rect {
  opacity: 1 !important;
}

.md-fences.md-fences-math {
  font-size: 1em;
}

.md-fences-advanced:not(.md-focus) {
  padding: 0;
  white-space: nowrap;
  border: 0;
}

.md-fences-advanced:not(.md-focus) {
  background-image: inherit;
  background-size: inherit;
  background-attachment: inherit;
  background-origin: inherit;
  background-clip: inherit;
  background-color: inherit;
  background-position: inherit;
  background-repeat: inherit;
}

.typora-export-show-outline .typora-export-content {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.typora-export-sidebar {
  width: 300px;
  font-size: 0.8rem;
  margin-top: 80px;
  margin-right: 18px;
}

.typora-export-show-outline #write {
  --webkit-flex: 2;
  flex: 2 1 0%;
}

.typora-export-sidebar .outline-content {
  position: fixed;
  top: 0;
  max-height: 100%;
  overflow: hidden auto;
  padding-bottom: 30px;
  padding-top: 60px;
  width: 300px;
}

@media screen and (max-width: 1024px) {
  .typora-export-sidebar,
  .typora-export-sidebar .outline-content {
    width: 240px;
  }
}

@media screen and (max-width: 800px) {
  .typora-export-sidebar {
    display: none;
  }
}

.outline-content li,
.outline-content ul {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
}

.outline-content ul {
  margin-top: 0;
  margin-bottom: 0;
}

.outline-content strong {
  font-weight: 400;
}

.outline-expander {
  width: 1rem;
  height: 1.428571429rem;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  padding-left: 4px;
}

.outline-expander::before {
  content: "";
  position: relative;
  font-family: Ionicons;
  display: inline-block;
  font-size: 8px;
  vertical-align: middle;
}

.outline-item {
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}

.outline-expander:hover::before {
  content: "";
}

.outline-h1 > .outline-item {
  padding-left: 0;
}

.outline-h2 > .outline-item {
  padding-left: 1em;
}

.outline-h3 > .outline-item {
  padding-left: 2em;
}

.outline-h4 > .outline-item {
  padding-left: 3em;
}

.outline-h5 > .outline-item {
  padding-left: 4em;
}

.outline-h6 > .outline-item {
  padding-left: 5em;
}

.outline-label {
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
}

.outline-label:hover {
  text-decoration: underline;
}

.outline-item:hover {
  border-color: #f5f5f5;
  background-color: var(--item-hover-bg-color);
}

.outline-item:hover {
  margin-left: -28px;
  margin-right: -28px;
  border-left-width: 28px;
  border-left-style: solid;
  border-left-color: transparent;
  border-right-width: 28px;
  border-right-style: solid;
  border-right-color: transparent;
}

.outline-item-single .outline-expander::before,
.outline-item-single .outline-expander:hover::before {
  display: none;
}

.outline-item-open > .outline-item > .outline-expander::before {
  content: "";
}

.outline-children {
  display: none;
}

.info-panel-tab-wrapper {
  display: none;
}

.outline-item-open > .outline-children {
  display: block;
}

.typora-export .outline-item {
  padding-top: 1px;
  padding-bottom: 1px;
}

.typora-export .outline-item:hover {
  margin-right: -8px;
  border-right-width: 8px;
  border-right-style: solid;
  border-right-color: transparent;
}

.typora-export .outline-expander::before {
  content: "+";
  font-family: inherit;
  top: -1px;
}

.typora-export .outline-expander:hover::before,
.typora-export .outline-item-open > .outline-item > .outline-expander::before {
  content: "−";
}

.typora-export-collapse-outline .outline-children {
  display: none;
}

.typora-export-collapse-outline .outline-item-open > .outline-children,
.typora-export-no-collapse-outline .outline-children {
  display: block;
}

.typora-export-no-collapse-outline .outline-expander::before {
  content: "" !important;
}

.typora-export-show-outline
  .outline-item-active
  > .outline-item
  .outline-label {
  font-weight: 700;
}

.md-inline-math-container mjx-container {
  zoom: 0.95;
}

:root {
  --side-bar-bg-color: #fafafa;
  --control-text-color: #777;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Open Sans", "Clear Sans", "Helvetica Neue", Helvetica, Arial,
    "Segoe UI Emoji", sans-serif;
  line-height: 2;
}

#write {
  max-width: 860px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 1400px) {
  #write {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1800px) {
  #write {
    max-width: 1200px;
  }
}

#write > ol:first-child,
#write > ul:first-child {
  margin-top: 30px;
}

a {
  color: #4183c4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.4;
  cursor: text;
}

h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
  text-decoration: none;
}

h1 code,
h1 tt {
  font-size: inherit;
}

h2 code,
h2 tt {
  font-size: inherit;
}

h3 code,
h3 tt {
  font-size: inherit;
}

h4 code,
h4 tt {
  font-size: inherit;
}

h5 code,
h5 tt {
  font-size: inherit;
}

h6 code,
h6 tt {
  font-size: inherit;
}

h1 {
  font-size: 2.25em;
  line-height: 1.2;
  border-bottom: 1px solid #eee;
}

h2 {
  font-size: 1.75em;
  line-height: 1.225;
  border-bottom: 1px solid #eee;
}

h3 {
  font-size: 1.5em;
  line-height: 1.43;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 1em;
  color: #777;
}

blockquote,
dl,
ol,
p,
table,
ul {
  margin: 0.8em 0;
}

li > ol,
li > ul {
  margin: 0 0;
}

hr {
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
  overflow: hidden;
  box-sizing: content-box;
}

li p.first {
  display: inline-block;
}

ol:first-child,
ul:first-child {
  margin-top: 0;
}

ol:last-child,
ul:last-child {
  margin-bottom: 0;
}

blockquote {
  border-left: 4px solid #dfe2e5;
  padding: 0 15px;
  color: #777;
}

blockquote blockquote {
  padding-right: 0;
}

table {
  padding: 0;
  word-break: initial;
}

table tr {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 0;
}

table tr:nth-child(2n),
thead {
  background-color: #f8f8f8;
}

table th {
  font-weight: 700;
  border: 1px solid #dfe2e5;
  border-bottom: 0;
  margin: 0;
  padding: 6px 13px;
}

table td {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 6px 13px;
}

table td:first-child,
table th:first-child {
  margin-top: 0;
}

table td:last-child,
table th:last-child {
  margin-bottom: 0;
}

.CodeMirror-lines {
  padding-left: 4px;
}

.code-tooltip {
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3);
  border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
  border: 1px solid #e7eaed;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 0;
  padding: 2px 4px 0 4px;
  font-size: 0.9em;
}

code {
  background-color: #f3f4f4;
  padding: 0 2px 0 2px;
}

.md-fences {
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 6px;
}

.md-task-list-item > input {
  margin-left: -1.3em;
}

@media print {
  html {
    font-size: 13px;
  }

  pre,
  table {
    page-break-inside: avoid;
  }

  pre {
    word-wrap: break-word;
  }
}

.md-fences {
  background-color: #f8f8f8;
}

#write pre.md-meta-block {
  padding: 1rem;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f7f7f7;
  border: 0;
  border-radius: 3px;
  color: #777;
  margin-top: 0 !important;
}

.mathjax-block > .code-tooltip {
  bottom: 0.375rem;
}

.md-mathjax-midline {
  background: #fafafa;
}

#write > h3.md-focus:before {
  left: -1.5625rem;
  top: 0.375rem;
}

#write > h4.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

#write > h5.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

#write > h6.md-focus:before {
  left: -1.5625rem;
  top: 0.285714286rem;
}

.md-image > .md-meta {
  border-radius: 3px;
  padding: 2px 0 0 4px;
  font-size: 0.9em;
  color: inherit;
}

.md-tag {
  color: #a7a7a7;
  opacity: 1;
}

.md-toc {
  margin-top: 20px;
  padding-bottom: 20px;
}

.sidebar-tabs {
  border-bottom: none;
}

#typora-quick-open {
  border: 1px solid #ddd;
  background-color: #f8f8f8;
}

#typora-quick-open-item {
  background-color: #fafafa;
  border-color: #fefefe #e5e5e5 #e5e5e5 #eee;
  border-style: solid;
  border-width: 1px;
}

.on-focus-mode blockquote {
  border-left-color: rgba(85, 85, 85, 0.12);
}

.context-menu,
.megamenu-content,
footer,
header {
  font-family: "Segoe UI", Arial, sans-serif;
}

.file-node-content:hover .file-node-icon,
.file-node-content:hover .file-node-open-state {
  visibility: visible;
}

.mac-seamless-mode #typora-sidebar {
  background-color: #fafafa;
  background-color: var(--side-bar-bg-color);
}

.md-lang {
  color: #b4654d;
}

#md-notification .btn {
  border: 0;
}

.dropdown-menu .divider {
  border-color: #e5e5e5;
  opacity: 0.4;
}

.ty-preferences .window-content {
  background-color: #fafafa;
}

.ty-preferences .nav-group-item.active {
  color: #fff;
  background: #999;
}

.menu-item-container a.menu-style-btn {
  background-color: #f5f8fa;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.8),
    hsla(0, 0%, 100%, 0)
  );
}

@media print {
  @page {
    margin: 0;
  }

  body.typora-export {
    padding-left: 0;
    padding-right: 0;
  }

  #write {
    padding: 0;
  }
}
</style>
